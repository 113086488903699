<!--
.. Mofified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <v-dialog v-model="display" max-width="600px">
      <template slot="activator" scope="props">
        <v-badge :value="!!numFilters" bordered color="tips" :content="numFilters">
          <v-btn small color="primary" v-on="props.on"> <v-icon small left>mdi-filter</v-icon>Filter </v-btn>
        </v-badge>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Signal Instance Filters</span>
        </v-card-title>
        <v-list density="compact">
          <v-list-item>
            <signal-definition-combobox v-model="local_signal" label="Signal Definitions" />
          </v-list-item>
          <v-list-item>
            <date-time-picker-menu v-model="local_start" label="Start" />
          </v-list-item>
          <v-list-item>
            <date-time-picker-menu v-model="local_end" label="End" />
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" variant="text" @click="applyFilters()"> Apply Filters </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>

  <script>
  import { sum, cloneDeep } from "lodash"
  import { mapFields } from "vuex-map-fields"
  import SignalDefinitionCombobox from "@/signal/SignalDefinitionCombobox.vue"
  import DateTimePickerMenu from "@/components/DateTimePickerMenu.vue"

  export default {
    name: "SignalInstanceTableFilterDialog",
    components: {
      SignalDefinitionCombobox,
      DateTimePickerMenu,
    },
    data() {
      return {
        display: false,
        local_signal: [],
        local_start: null,
        local_end: null,
      }
    },
    created() {
      this.local_signal = cloneDeep(this.signal)
      this.local_start = cloneDeep(this.start)
      this.local_end = cloneDeep(this.end)
    },
    computed: {
      ...mapFields("signal", [
        "instanceTable.options.filters.signal",
        "instanceTable.options.filters.created_at.start",
        "instanceTable.options.filters.created_at.end",
      ]),
      numFilters: function () {
        return sum([
          this.signal.length,
          this.start ? 1 : 0,
          this.end ? 1 : 0,
        ])
      },
    },
    methods: {
      applyFilters() {
        // we set the filter values
        this.signal = this.local_signal
        this.start = this.local_start
        this.end = this.local_end

        // we close the dialog
        this.display = false
      },
    },
  }
  </script>
