var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"headline"},[_c('v-icon',{attrs:{"left":"","large":"","color":"warning"}},[_vm._v("mdi-signal")]),_vm._v(" Active Signal Objects ")],1)]),_c('v-col',{staticClass:"text-right"},[_c('table-filter-dialog')],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"sort-by":_vm.sortBy,"sort-desc":_vm.descending,"loading":_vm.loading,"loading-text":"Loading... Please wait","footer-props":{
            'items-per-page-options': [25, 50, 100],
          }},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.descending=$event},"update:sort-desc":function($event){_vm.descending=$event}},scopedSlots:_vm._u([{key:"item.case",fn:function({ item }){return [(item.case)?_c('case-popover',{model:{value:(item.case),callback:function ($$v) {_vm.$set(item, "case", $$v)},expression:"item.case"}}):_vm._e()]}},{key:"item.signal",fn:function({ item }){return [_c('signal-popover',{attrs:{"signal":item.signal}})]}},{key:"item.project.name",fn:function({ item }){return [_c('project-card',{attrs:{"project":item.project}})]}},{key:"item.filter_action",fn:function({ item }){return [_c('v-chip',{attrs:{"small":"","text-color":"white","color":item.filter_action === 'snooze'
                  ? 'blue'
                  : item.filter_action === 'deduplicate'
                  ? 'warning'
                  : 'grey'}},[_vm._v(" "+_vm._s(item.filter_action === "snooze" ? "Snoozed" : item.filter_action === "deduplicate" ? "Duplicate" : "Not Filtered")+" ")])]}},{key:"item.entities",fn:function({ item }){return [_c('v-row',[_c('v-col',{staticClass:"d-flex flex-wrap"},_vm._l((item.entities),function(entity,index){return _c('div',{key:index,staticClass:"mr-2 mb-2"},[_c('entity-popover',{attrs:{"entity":entity}})],1)}),0)],1)]}},{key:"item.created_at",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm._f("formatRelativeDate")(item.created_at)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.created_at)))])])]}},{key:"item.data-table-actions",fn:function({ item }){return [_c('raw-signal-viewer',{model:{value:(item.raw),callback:function ($$v) {_vm.$set(item, "raw", $$v)},expression:"item.raw"}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showRun({ type: 'signal', data: item })}}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-play-circle-outline")])],1),_c('workflow-run-modal')]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }