<!--
.. Mofified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <v-row no-gutters class="mb-2">
      <v-col>
        <div class="headline">
          <v-icon left large color="warning">mdi-signal</v-icon>
          Active Signal Objects
        </div>
      </v-col>
      <v-col class="text-right">
        <table-filter-dialog />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="descending"
            :loading="loading"
            loading-text="Loading... Please wait"
            :footer-props="{
              'items-per-page-options': [25, 50, 100],
            }"
          >
            <template v-slot:item.case="{ item }">
              <case-popover v-if="item.case" v-model="item.case" />
            </template>
            <template v-slot:item.signal="{ item }">
              <signal-popover :signal="item.signal" />
            </template>
            <template v-slot:item.project.name="{ item }">
              <project-card :project="item.project" />
            </template>
            <template v-slot:item.filter_action="{ item }">
              <v-chip
                small
                text-color="white"
                :color="
                  item.filter_action === 'snooze'
                    ? 'blue'
                    : item.filter_action === 'deduplicate'
                    ? 'warning'
                    : 'grey'
                "
              >
                {{
                  item.filter_action === "snooze"
                    ? "Snoozed"
                    : item.filter_action === "deduplicate"
                    ? "Duplicate"
                    : "Not Filtered"
                }}
              </v-chip>
            </template>
            <template v-slot:item.entities="{ item }">
              <v-row>
                <v-col class="d-flex flex-wrap">
                  <div
                    v-for="(entity, index) in item.entities"
                    :key="index"
                    class="mr-2 mb-2"
                  >
                    <entity-popover :entity="entity" />
                </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.created_at="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ item.created_at | formatRelativeDate }}</span>
                </template>
                <span>{{ item.created_at | formatDate }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.data-table-actions="{ item }">
              <raw-signal-viewer v-model="item.raw" />
              <v-btn icon @click="showRun({ type: 'signal', data: item })">
                <v-icon color="success">mdi-play-circle-outline</v-icon>
              </v-btn>
              <workflow-run-modal />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import RouterUtils from "@/router/utils"
import SignalPopover from "@/signal/SignalPopover.vue"
import CasePopover from "@/case/CasePopover.vue"
import RawSignalViewer from "@/signal/RawSignalViewer.vue"
import TableFilterDialog from "@/signal/TableFilterDialog.vue"
import ProjectCard from "@/project/ProjectCard.vue"
import WorkflowRunModal from "@/workflow/RunModal.vue"
import EntityPopover from "@/entity/EntityPopover.vue"

export default {
  name: "SignalInstanceTable",

  components: {
    SignalPopover,
    CasePopover,
    RawSignalViewer,
    TableFilterDialog,
    ProjectCard,
    WorkflowRunModal,
    EntityPopover,
  },

  data() {
    return {
      headers: [
        { text: "OCO", value: "case", sortable: false },
        { text: "Signal", value: "signal", sortable: false },
        { text: "Project", value: "project.name", sortable: true },
        { text: "Filter Action", value: "filter_action", sortable: true },
        { text: "Entities", value: "entities", sortable: false },
        { text: "Created At", value: "created_at" },
        { text: "", value: "data-table-actions", sortable: false, align: "end" },
      ],
    }
  },

  computed: {
    ...mapFields("signal", [
      "instanceTable.loading",
      "instanceTable.options.descending",
      "instanceTable.options.filters",
      "instanceTable.options.itemsPerPage",
      "instanceTable.options.page",
      "instanceTable.options.q",
      "instanceTable.options.filters.signal",
      "instanceTable.options.filters.created_at.start",
      "instanceTable.options.filters.created_at.end",
      "instanceTable.options.sortBy",
      "instanceTable.rows.items",
      "instanceTable.rows.total",
    ]),
    ...mapFields("route", ["query"]),
    ...mapFields("auth", ["currentUser.projects"]),

    defaultUserProjects: {
      get() {
        let d = null
        if (this.projects) {
          let d = this.projects.filter((v) => v.default === true)
          return d.map((v) => v.project)
        }
        return d
      },
    },
  },

  methods: {
    ...mapActions("signal", ["getAllInstances"]),
    ...mapActions("workflow", ["showRun"]),
  },

  created() {
    this.filters = {
      ...this.filters,
      ...RouterUtils.deserializeFilters(this.query),
      project: this.defaultUserProjects,
    }

    this.getAllInstances()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAllInstances()
      }
    )

    this.$watch(
      (vm) => [
        vm.q,
        vm.sortBy,
        vm.itemsPerPage,
        vm.descending,
        vm.project,
        vm.signal,
      ],
      () => {
        this.page = 1
        RouterUtils.updateURLFilters(this.filters)
        this.getAllInstances()
      }
    )
  },
}
</script>
